import React, { useState } from 'react';

import { 
		Toolbar,
    Typography,
    IconButton,
    styled,
    Container,
    Grid,
    InputLabel,
    OutlinedInput,
    Box,
    TextField,
    Button,
    Drawer,
		InputAdornment,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ExpandMore , Close as CloseIcon } from "@material-ui/icons";
import * as Yup from "yup";
import { Formik , Form, ErrorMessage} from "formik"
import { FaArrowLeft } from "react-icons/fa";

import { BlockComponent } from "../../framework/src/BlockComponent";
import { IBlock } from "../../framework/src/IBlock";
import { getStorageData } from "../../framework/src/Utilities";
import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { runEngine } from '../../framework/src/RunEngine';
export const configJSON = require("./config");

const success_icon = require("../assets/success-icon.png");


const styles = {
  paper: {
    width: '100%',
    overflow: 'hidden',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    },
	svg: {
		color: "#94A3B8",
		height: "25px",
		width: "25px",
	},

    form: {
      marginTop: '10px',
      marginBottom: 0,
      width: '100%', // Fix IE  11  issue.
    },
    submit: {
      // margin: theme.spacing(3, 0, 5),
      color: "white",
    },
    inputFieldTitle: {
      fontSize: '14px',
      fontWeight: '700',
      margin: '0 0 4px',
      color: '#F1F5F9',
      lineHeight: '22px',
			marginTop: '15px',
    },
    inputFieldValue: {
      color: '#CBD5E1',
      backgroundColor: 'transparent',
      borderRadius: '8px',
      fontFamily: 'Inter',
      border: '1px solid #64748B',
    },
    inputFieldModelValue: {
      color: '#E2E8F0',
      backgroundColor: 'transparent',
      borderRadius: '8px',
      fontFamily: 'Inter',
      border: '1px solid #64748B',
    },
    successStatusWrapper: {
			width: "90%",
			display: "flex",
			justifyContent: "center",
			margin: 'auto'
		},
    successStatus: {
      maxWidth: "300px",
      padding: "10px",
      display: "flex",
      margin: "0 auto",
      color: "#0F172A",
      fontSize: "16px",
      lineHeight: "24px",
      // top: '57vh',
      width: 'calc(100vw - 80px)',
      borderRadius: "8px",
      backgroundColor: "#FEE2E2",
      '& img': {
        marginRight: "8px",
        width: "24px"
      },
      alignItems: 'center',
      gap: '10px',
      justifyContent: 'center',
      bottom: 40
    },
    autoCompleteStyle: {
      margin: '0 0',
      border:'1px solid rgb(100, 116, 139)',
      borderRadius: '8px',
    },
    enterCardDetails: {
      fontSize: "14px",
      fontWeight: 'bold',
      lineHeight: "22px",
      color: "#F8FAFC",
      'text-align':"center",
      height:"22px",
      'text-transform': 'uppercase',
    },
};

const validationSchema = Yup.object({
  region: Yup.object().shape({
    id: Yup.number().required("Region ID is required"),
    name: Yup.string().required("Region is required to add new screen").nullable().trim().notOneOf([""]), // Ensure name is not empty or null
  }).required("Region is required to add new screen"),
  interactive: Yup.string().required("Interactive data is required to add new screen"),
  screenName: Yup.string().required("Screen Name is required"),
  screenID: Yup.string().required("Screen Id is required"),
  screenFirst: Yup.string()
    .matches(/^[0-9]+$/, 'Only number allowed'),
  screenLast: Yup.string()
    .matches(/^[0-9]+$/, 'Only number allowed'),
  price: Yup.number()
    .when('interactive', {
      is: (value) => !!value,
      then: Yup.number()
        .required("Price is required when interactive is selected")
        .typeError("Price must be a number"),
      otherwise: Yup.number()
        .typeError("Price must be a number")
    }),
})
.test('screen-dimensions', 'Screen Dimensions are required and must be numbers', function(value) {
  const { screenFirst, screenLast } = value;
  if (!screenFirst || !screenLast) {
    return this.createError({ path: 'screenFirst', message: 'Screen Dimensions are required and must be numbers' });
  }
  return true; 
});

const addValidationSchema = Yup.object({
  addName: Yup.string().required("Name is required"),
})

interface regionData {
	id: number;
	name: string;
}

interface ScreenInfo {
  region: regionData;
  screenName: string;
  screenID: string;
  screenFirst: string;
  screenLast: string;
  price: string;
  interactive: 'Yes' | 'NO' | '';
}

export interface Props {
  navigation: any;
}

interface SS {
  navigation: any;
}

class AddNewScreen extends BlockComponent<Props, any, SS> {
  addScreeCardApiID: string = '';
  getRegionApiID: string = '';
  addRegionApiID: string = '';

  constructor(props: any) {
    super(props);

		this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      modalAdd: false,
			regionList: []
    };
  }

	async componentDidMount() {
    super.componentDidMount();

		this.getRegionList();
	}

  handleChangeRegion = (newValue: string) => {
    if (newValue === "+ Add new") {
      this.setState({ modalAdd: !this.state.modalAdd });
    }
  };

  handleAddNewDrawer = () => {
    this.setState({ modalAdd: !this.state.modalAdd });
  };

	apiCall = async ({
		method, endPoint, contentType, body, isFormData
	}: any) => {
		const token = await getStorageData("token");
    const header = {
      "Content-Type": contentType,
      token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    body && requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      isFormData ? body : JSON.stringify(body)
    );

		runEngine.sendMessage(requestMessage.id, requestMessage);

		return requestMessage;
	}

	getRegionList = async () => {
		const GetRegionAPI = await this.apiCall({
			method: configJSON.httpGetMethod, 
			endPoint: configJSON.getRegionApiEndPoint, 
			contentType: "application/json",
		})

		this.getRegionApiID = GetRegionAPI.messageId;
	}

  createRegion = async (regionName: string) => {
		const GetRegionAPI = await this.apiCall({
			method: configJSON.httpPostMethod, 
			endPoint: configJSON.addRegionApiEndPoint, 
			contentType: "application/json",
      body: {
        "name": regionName
      }
		})

		this.addRegionApiID = GetRegionAPI.messageId;
	}

  handleUpdatePassword = async (values: ScreenInfo) => {
		const UpdatePasswordAPI = await this.apiCall({
			method: configJSON.httpPostMethod, 
			endPoint: configJSON.addScreenApiEndPoint, 
			contentType: "application/json", 
			body: {
        screen: {
          screen_name: values.screenName,
          price: values.price,
          region_id: values.region?.id,
          media_format: "image",
          latitude: 28.6115,
          longitude: 77.2135,
          width: values.screenFirst,
          height: values.screenLast,
          screen_number: values.screenID,
          interactive: !!(values.interactive === 'Yes'),
          availability: "available",
          host_url: "http://localhost:3000/displayScreen",
        },
      }
		})

		this.addScreeCardApiID = UpdatePasswordAPI.messageId;

  };

	goBack = () => {
		this.props.navigation.goBack()
	}

	async receive(from: string, message: Message) { 
		let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
		const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

		if(apiRequestCallId == this.addScreeCardApiID){ this.handleAddScreenResponse(responseJson) }
		if(apiRequestCallId == this.getRegionApiID){ this.setState({regionList: responseJson.regions}) }
		if(apiRequestCallId == this.addRegionApiID){ this.setState({modalAdd: false, errorMessage:responseJson.message}) }

	}

	handleAddScreenResponse = ( response: any) => {
		if(!response.errors) {
			this.goBack();
		}

	}

	
  render() {
    const { modalAdd } = this.state;

    return (
      <Box style={{background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)', height: '100%'}}>
				<Toolbar>
					<FaArrowLeft style={{color: '#FFF'}} data-test-id="go-back" onClick={this.goBack} />
				</Toolbar>
        <DetailedScreenBox>
          <Container maxWidth='md'>
            <h1 style={{ color: "white" }}>Add New</h1>
            <Formik
              initialValues={{
                region: {id: 0, name: ''},
                screenName: '',
                screenID: '',
                screenFirst: '',
                screenLast: '',
                price: '',
                interactive: '',
              }}
              validationSchema={validationSchema}
              onSubmit={(values: ScreenInfo) => this.handleUpdatePassword(values)}
            >
              {({ values, handleChange, handleBlur, errors, touched, setFieldValue }) => (
                <Form style={styles.form}>
                  <InputLabel style={styles.inputFieldTitle}>Region</InputLabel>
                  <Autocomplete
                    fullWidth
                    style={styles.autoCompleteStyle}
                    options={[...this.state.regionList, {id: 0, name: '+ Add new'}]}
                    PaperComponent={(props) => <CustomPaper {...props} />}
                    getOptionLabel={(option: any) => option.name}
                    renderInput={(params) => (
                      <MenuIcon>
                        <StyledTextField name='region' style={{ color: "#CBD5E1" }} {...params} value={values.region?.name || ''} placeholder="Select option" variant="outlined" />
                      </MenuIcon>
                    )}
                    popupIcon={<ExpandMore style={{ color: '#94A3B8' }} />}
                    value={values.region}
                    onBlur={handleBlur}
                    onChange={(event, newValue: any) => {
                      if (newValue?.name !== "+ Add new") setFieldValue('region', newValue);
                      this.handleChangeRegion(newValue?.name);
                    }}
                  />
                  <ErrorMessage style={{ color: 'red' }} name="region.name" component="div" />

                  <InputLabel style={styles.inputFieldTitle}>Screen Name</InputLabel>
                  <StyledTextField
                    variant="outlined"
                    fullWidth
                    name="screenName"
                    value={values.screenName}
                    type={'text'}
                    id="screenName"
                    style={styles.inputFieldValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage style={{ color: 'red' }} name="screenName" component="div" />

									<InputLabel style={styles.inputFieldTitle}>Screen ID</InputLabel>
									<StyledTextField
										variant="outlined"
										fullWidth
										name="screenID"
										value={values.screenID}
										type={'text'}
										id="screenID"
										style={styles.inputFieldValue}
										onChange={handleChange}
										onBlur={handleBlur}
									/>
                  <ErrorMessage style={{ color: 'red' }} name="screenID" component="div" />
									
									<InputLabel style={styles.inputFieldTitle}>Screen Dimensions</InputLabel>
									<Grid container spacing={2} justifyContent="center" alignItems="center">
										<Grid item xs={6} style={{paddingRight:"8px"}}>
											<StyledTextField
												variant="outlined"
												fullWidth
												name="screenFirst"
												value={values.screenFirst}
												type={'text'}
												id="screenFirst"
												style={styles.inputFieldValue}
												onChange={handleChange}
												onBlur={handleBlur}              
											/>
										</Grid>
										<Grid item xs={6} style={{paddingRight:"8px"}}>
											<StyledTextField
												variant="outlined"
												fullWidth
												name="screenLast"
												value={values.screenLast}
												type={'text'}
												id="screenLast"
												style={styles.inputFieldValue}
												onChange={handleChange}
												onBlur={handleBlur}                
											/>
										</Grid>
									</Grid>
									<ErrorMessage style={{color: 'red'}}  name="screenFirst" component="div" />
                  
									<InputLabel style={styles.inputFieldTitle}>	Interactive </InputLabel>
									<Autocomplete
										id="combo-box-demo"
										fullWidth
										style={styles.autoCompleteStyle}
										options={['Yes', 'NO']}
										PaperComponent={(props)=> <CustomPaper {...props} / >}
										getOptionLabel={(option) => option}
										renderInput={(params) => <MenuIcon><StyledTextField style={{color:"#CBD5E1"}} {...params} placeholder="Select option" variant="outlined" /></MenuIcon>}
										popupIcon={<ExpandMore style={{color: '#94A3B8'}} />}
										value={values.interactive}
										onChange={(event: any, newValue: any) => setFieldValue('interactive', newValue)}
									/>
                  <ErrorMessage style={{ color: 'red' }} name="interactive" component="div" />

									{values.interactive && (
										<>
											<InputLabel style={styles.inputFieldTitle}>{
												values.interactive === 'Yes' ? 'Price' : 'Price per 30 mins'
											}</InputLabel>
											<StyledTextField
												variant="outlined"
												fullWidth
												name="price"
												value={values.price}
												type={'number'}
												id="price"
												style={styles.inputFieldValue}
												onChange={handleChange}
												onBlur={handleBlur}
												InputProps={{
													startAdornment: <InputAdornment position="start"><span  style={{color: '#94A3B8'}}>$</span></InputAdornment>,
												}}
											/>
	                  <ErrorMessage style={{ color: 'red' }} name="price" component="div" />
											
										</>
									)}
									
                  <ButtonWrapper>
                    <CancelButton type='button' onClick={this.goBack}>
                      {"Cancel"}
                    </CancelButton>
                  </ButtonWrapper>
                  <ButtonWrapper>
                    <SaveButton type='submit'>
                      {"Save"}
                    </SaveButton>
                  </ButtonWrapper>
                </Form>
              )}
            </Formik>
          </Container>
					<Drawer
						anchor="bottom"
						open={modalAdd}
						onClose={this.handleAddNewDrawer}
						PaperProps={{ elevation: 0, style: { backgroundColor: 'black' } }}
					>
						<Box style={{ padding: '15px 0px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <Box style={{ display: 'flex', justifyContent: 'end' }}>
								<IconButton style={{ color: '#000' }}>
									<CloseIcon />
								</IconButton>
							</Box>
							<div style={{ flex: '1' }}>
								<Typography variant="body1" style={styles.enterCardDetails}>
									Add new region
								</Typography>
							</div>
							<Box style={{ display: 'flex', justifyContent: 'end' }}>
								<IconButton style={{ color: '#FFF' }} onClick={this.handleAddNewDrawer}>
									<CloseIcon />
								</IconButton>
							</Box>
						</Box>
						<Container maxWidth='md'>
							<Formik
								initialValues={{ screen_region_name: '' }}
								onSubmit={(values) => this.createRegion(values.screen_region_name)}
							>
								{({ values, handleChange, handleBlur }) => (
									<Form style={styles.form}>
										<InputLabel style={styles.inputFieldTitle}>Name</InputLabel>
										<StyledTextField
											fullWidth
                      variant="outlined"
											name="screen_region_name"
											value={values.screen_region_name}
											type={'text'}
											id="screen_region_name"
											style={styles.inputFieldModelValue}
											onChange={handleChange}
											onBlur={handleBlur}
											placeholder='Enter region name'
										/>
										
										<ButtonWrapper>
											<SaveButton
												type='submit'
                        style={{marginTop: '20px'}}
												disabled={!values.screen_region_name}
											>
												Save
											</SaveButton>
										</ButtonWrapper>
									</Form>
								)}
							</Formik>
						</Container>
					</Drawer>
          {this.state.viewAd &&
            <Box style={styles.successStatusWrapper} data-test-id="card_success">
              <Box style={{...styles.successStatus, position: 'fixed'}}>
                <img src={success_icon} /> {this.state?.errorMessage || ''}
              </Box>
            </Box>
          }
        </DetailedScreenBox>
      </Box>
    );
  }
}

export default AddNewScreen;


const CustomPaper = styled('ul')(({theme})=>({
  backgroundColor:"black",
  color:"white"
}))

const StyledTextField = styled(TextField)({
  borderRadius: "8px",
  width: "100%",
  "& .MuiOutlinedInput-root":{
    height: "48px",
  },
  "& .MuiOutlinedInput-input":{
    color: "#CBD5E1",
  },
  "&.MuiOutlinedInput-notchedOutline":{
    border: "none"
  },
  "&:hover .MuiOutlinedInput-notchedOutline":{
    borderColor: "transparent"
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent"
  },
  "& .Mui-focused .MuiOutlinedInput-notchedOutline":{
    borderWidth: "0px"
  },
  "& .MuiOutlinedInput-input::placeholder":{
    color: "#94A3B8",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    opacity:1
  },
  "& MuiInputBase-root MuiOutlinedInput-root MuiInputBase-formControl MuiInputBase-adornedStart MuiOutlinedInput-adornedStart": {
    border: "1px solid",
    borderImageSource: "linear-gradient(175.74deg, #FFFFFF 3.31%, #999999 96.39%)",
  },
	'& .MuiOutlinedInput-input input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button': { 
    '-webkit-appearance': 'none',
    '-moz-appearance': 'none',
    'appearance': 'none',
    'margin': 0,
	},
	'& input[type=number]': {
    '-moz-appearance': 'textfield'
	}

});

const SaveButton = styled(Button)({
	padding: '16px 109px 16px 108px',
	background: 'linear-gradient(174.53deg, #FFFFFF 4.52%, #919191 103.95%)',
	gap: '8px',
	borderRadius: '8px',
	display: 'flex',
	bottom: '15px',
	margin: 'auto',
	fontWeight: 'bold',
	fontSize: '16px',
	width: '100%'
});

const CancelButton = styled(Button)({
	background: 'none',
  color:'white',
	gap: '8px',
	borderRadius: '8px',
	display: 'flex',
	bottom: '0px',
	margin: 'auto',
	fontWeight: 'bold',
	fontSize: '16px',
	width: '100%'
});

const ButtonWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '16px 0',
  width: '100%',
});

const DetailedScreenBox = styled(Box)({
  
  '& .detailTitle': {
    color: '#fff',
    fontSize: '30px',
    fontWeight: 700,
    lineHeight: '40px',
    letterSpacing: '-0.15px',
    textAlign: 'left',
    padding: '8px 20px'
  },
  '& .list-items': {
    width: '100%',
    maxWidth: 360,
    
  },
  "& .MuiListItem-gutters": {
    paddingLeft: '0px',
    paddingRight: '0px',
    marginLeft: '20px',
    borderBottom: '1px solid #334155',
  },
  '& .MuiListItemText-primary': {
    fontWeight: 'bold',
    color: '#CBD5E1',
    fontSize: '14px',
  },
  '& .MuiListItemText-secondary': {
    color: '#94A3B8',
    fontSize: '16px',
  },
});

const MenuIcon = styled(Box)({
  '& .MuiButtonBase-root': {
    color: 'white'
  },
  '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input' :{
    color:'white'
  }
});